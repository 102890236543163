<template>
  <v-container fill-height fluid>
    <v-layout wrap>
      <v-flex>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-btn
              color="primary"
              class="float-right"
              top
              right
              @click="$router.go(-1)"
              >Zur Übersicht</v-btn
            >
          </v-col>
          <v-col cols="12" sm="12" md="8" lg="6">
            <div class="card-title">Wartungsblock Informationen</div>
            <v-card class="mx-auto" flat>
              <v-card-text>
                <v-container grid-list-md>
                  <v-form ref="form2">
                    <v-layout wrap>
                      <v-flex xs12 sm12 md12>
                        <v-text-field
                          v-model="block.name"
                          label="Name*"
                          :rules="[rules.required]"
                          required
                        ></v-text-field>
                      </v-flex>
                      <!--   <v-flex xs12 sm12 md4>
                        <v-text-field type="number" v-model="block.duration" label="Dauer"></v-text-field>
                      </v-flex> -->
                      <v-flex xs12 sm12 md8>
                        <v-menu
                          v-model="menu2"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="formattedRefDate"
                              label="Referenz-Datum für Wartungszyklus"
                              prepend-icon="event"
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            no-title
                            first-day-of-week="1"
                            v-model="block.refdate"
                            @input="menu2 = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-flex>
                      <v-flex xs12 sm12 md4>
                        <v-text-field
                          type="number"
                          v-model="block.cycle"
                          label="Wartungszyklus"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm12 md6>
                        <v-menu
                          v-model="menu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="formattedDate"
                              label="Nächste Wartung am*"
                              prepend-icon="event"
                              readonly
                              :rules="[rules.required]"
                              required
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            no-title
                            first-day-of-week="1"
                            v-model="block.nextservice"
                            @input="menu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-flex>

                      <v-flex xs12 sm12 md6>
                        <v-text-field
                          type="number"
                          v-model="block.price"
                          label="Preis"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm12>
                        <div v-if="mode === 'edit'">
                          Letzte Wartung: {{ $formatDate(block.lastservice) }}
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-form>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" offset-md="6" offset-lg="6" sm="12" md="6" lg="6">
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Suche"
              ></v-text-field>
            </v-col>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-data-table
              :headers="headers"
              height="inherit"
              :search="search"
              :items="blockanlagen"
              :items-per-page="-1"
              hide-default-footer
              sort-by="name"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>Anlagen</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" @click="openDialog()" color="success">
                        Anlagen hinzufügen
                      </v-btn>
                    </template>
                    <span>Anlagen diesem Warungs-Block hinzufügen</span>
                  </v-tooltip>
                </v-toolbar>
              </template>
              <template v-slot:item.action="{ item }">
                <div class="text-center">
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        @click="removeAnlage(item)"
                        color="error"
                        small
                        fab
                      >
                        <i class="material-icons">delete</i>
                      </v-btn>
                    </template>
                    <span>Anlagen aus diesem Warungs-Block entfernen</span>
                  </v-tooltip>
                </div>
              </template>
              <template v-slot:item.timestamps="{ item }">
                <small>Erstellt am: {{ $formatDate(item.created_at) }}</small>
                <br />
                <small
                  >Aktualsiert am: {{ $formatDate(item.updated_at) }}</small
                >
              </template>
            </v-data-table>
          </v-col>
          <!--    <v-col cols="12" sm="12" md="12" lg="12">
            <div class="card">
              <div class="card-header card-header-primary">
                <div class="card-title">Dokumente</div>
              </div>
              <div class="card-body" v-if="block && block.images">
                <div
                  class="img-thumb"
                  v-for="(image, i) in block.images.split(';')"
                  :key="i"
                >
                  <v-img
                    :src="getImage(image)"
                    contain
                    @click="showImg(image)"
                  ></v-img>
                  <v-btn text color="error" @click="deleteImage(i)"
                    >Löschen</v-btn
                  >
                </div>
              </div>
            </div>
          </v-col> -->
          <v-col cols="12" sm="12">
            <v-data-table
              :headers="headersdocuments"
              height="inherit"
              hide-default-footer
              :items-per-page="-1"
              :items="documents"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title
                    >Dokumente ({{ documents.length }})</v-toolbar-title
                  >
                </v-toolbar>
              </template>
              <template v-slot:item.created_at="{ item }">{{
                $formatDateTime(item.created_at)
              }}</template>
              <template v-slot:item.filename="{ item }">
                <div class="link" @click="$openDoc(item)">
                  {{ item.filename }}
                </div>
              </template>
              <template v-slot:item.action="{ item }">
                <div class="text-center inline-flex">
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        v-if="role != 'worker'"
                        @click="confirmDeleteDoc(item)"
                        color="error"
                        small
                        fab
                      >
                        <i class="material-icons">delete</i>
                      </v-btn>
                    </template>
                    <span>Dokument löschen</span>
                  </v-tooltip>
                </div>
              </template>
              <template v-slot:footer>
                <hr class="my-5" />
                <v-row>
                  <v-col cols="12" sm="9" md="9" lg="9">
                    <v-file-input
                      v-model="docs"
                      color="primary accent-4"
                      counter
                      label="Hier klicken um Dokumente hochzuladen"
                      multiple
                      placeholder="Hier klicken um Dokumente hochzuladen"
                      prepend-icon="mdi-paperclip"
                      outlined
                      :show-size="1000"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3">
                    <v-btn
                      @click="uploadDocs()"
                      color="primary"
                      :disabled="docs.length === 0"
                      >Dokumente Hochladen</v-btn
                    >
                  </v-col>
                </v-row>
              </template>
            </v-data-table>
          </v-col>
          <!--  <v-file-input
              v-model="images"
              color="primary accent-4"
              counter
              label="Bilder"
              multiple
              placeholder="Hier klicken um Bilder hochzuladen"
              prepend-icon="mdi-paperclip"
              outlined
              :show-size="1000"
            >
              <template v-slot:selection="{ index, text }">
                <v-chip
                  v-if="index < 2"
                  color="primary accent-4"
                  dark
                  label
                  small
                  >{{ text }}</v-chip
                >

                <span
                  v-else-if="index === 2"
                  class="overline grey--text text--darken-3 mx-2"
                  >+{{ files.length - 2 }} File(s)</span
                >
              </template>
            </v-file-input>
          </v-col>
          <v-col cols="12" sm="3" md="3" lg="3">
            <v-btn
              @click="uploadImages()"
              color="primary"
              :disabled="images.length === 0"
              >Bilder Hochladen</v-btn
            >
          </v-col> -->
          <v-col cols="12" sm="6" md="6">
            <v-btn @click="saveBlock()" color="success" normal
              >Wartungsblock speichern</v-btn
            >
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
    <v-dialog v-model="dialog" persistent max-width="1300px">
      <v-card>
        <v-card-title>
          <span class="headline">Anlagen hinzufügen</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-data-table
              show-select
              hide-default-footer
              :headers="headers"
              :items-per-page="-1"
              v-model="selected"
              height="inherit"
              :items="anlagen"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>Anlagen</v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
              </template>
              <template v-slot:item.timestamps="{ item }">
                <small>Erstellt am: {{ $formatDate(item.created_at) }}</small>
                <br />
                <small
                  >Aktualsiert am: {{ $formatDate(item.updated_at) }}</small
                >
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            :disabled="!selected.length"
            text
            @click="addAnlagen()"
            >{{ selected.length }} Anlagen hinzufügen</v-btn
          >
          <v-btn color="error" text @click="dialog = false">Schließen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="image" width="90%" transition="dialog-bottom-transition">
      <v-toolbar dark color="primary">
        <v-btn
          icon
          dark
          @click="
            image = false;
            selectedimage = '';
          "
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-img :src="getImage(selectedimage)" contain></v-img>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      selectedimage: "",
      image: false,
      docs: [],
      search:"",
      documents: [],
      anlagen: [],
      images: [],
      blockanlagen: [],
      selected: [],
      block: {},
      menu: false,
      menu2: false,
      headersdocuments: [
        {
          text: "ID",
          align: "left",
          sortable: false,
          value: "id",
        },
        {
          text: "Bezeichnung",
          align: "left",
          sortable: false,
          value: "filename",
        },
        {
          text: "Hochgeladen von",
          align: "left",
          value: "creator",
        },
        {
          text: "Hochgeladen am",
          align: "left",
          value: "created_at",
        },
        {
          text: "Aktion",
          align: "center",
          sortable: false,
          value: "action",
        },
      ],
      headers: [
        {
          text: "ID",
          align: "left",
          sortable: false,
          value: "id",
        },
        {
          text: "Anlagenbezeichnung",
          align: "left",
          sortable: true,
          value: "name",
        },
        {
          text: "Gebäude",
          align: "left",
          sortable: false,
          value: "gebaeude",
        },
        {
          text: "Standort",
          align: "left",
          sortable: false,
          value: "standort",
        },
        {
          text: "Typ",
          align: "left",
          sortable: false,
          value: "typ",
        },
        {
          text: "Seriennummer",
          align: "left",
          sortable: false,
          value: "seriennummer",
        },
        {
          text: "Baujahr",
          align: "left",
          sortable: false,
          value: "baujahr",
        },
        {
          text: "Bemerkung",
          align: "left",
          sortable: false,
          value: "comment",
        },
        {
          text: "Zeitstempel",
          align: "left",
          sortable: false,
          value: "timestamps",
        },
        {
          text: "Aktion",
          align: "center",
          sortable: false,
          value: "action",
        },
      ],
      rules: {
        required: (value) => !!value || "Required.",
      },
    };
  },
  computed: {
    formattedDate() {
      return this.block.nextservice
        ? this.$formatDate(this.block.nextservice)
        : "";
    },
    formattedRefDate() {
      return this.block.refdate ? this.$formatDate(this.block.refdate) : "";
    },
    role() {
      return this.$store.getters.user.role;
    },
    id() {
      return this.$route.params.id;
    },
    mode() {
      return this.$route.params.mode;
    },
  },
  mounted: function () {
    this.getBlockInfo();
  },
  methods: {
    getImage(img) {
      return this.$http.defaults.serverURL + "/uploads/" + img;
    },
    showImg(img) {
      this.selectedimage = img;
      this.image = true;
    },
    uploadDocs() {
      this.$uploadDocs(this.docs, this.id, "block", "customer")
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (response.data && response.data.success && response.data.files) {
            const id = this.id;
            const username = this.$store.getters.user.name;
            const userid = this.$store.getters.user.id;
            response.data.files.forEach((file) => {
              this.documents.push({
                id: file.id,
                path: file.path,
                filename: file.filename,
                created_at: this.$formatDateTimeISO(new Date()),
                created_by: userid,
                creator: username,
                refid: id,
              });
            });
            this.docs = [];
            const msg = "Dokumente wurden erfolgreich hochgeladen.";
            const color = "success";
            this.$store.dispatch("snackbar", { msg, color });
          } else {
            const msg =
              "Dokumente konnten nicht hochgeladen werden. Bitte versuchen Sie es erneut.";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    deleteImage(index) {
      if (this.block && this.block.images) {
        let images = this.block.images.split(";");
        images.splice(index, 1);
        this.block.images = images.join(";");
      }
    },
    uploadImages() {
      let formData = new FormData();
      let images = this.images;
      let that = this;
      for (var i = 0; i < images.length; i++) {
        let file = images[i];
        formData.append("images[" + i + "]", file);
      }
      this.$http({
        method: "post",
        url: "uploadImage.php",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success &&
            response.data.filenames
          ) {
            that.images = [];
            let imgs = that.block.images ? that.block.images.split(";") : [];
            imgs = imgs.concat(response.data.filenames);
            that.block.images = imgs.join(";");
            const msg = "Bilder wurden erfolgreich hochgeladen.";
            const color = "success";
            that.$store.dispatch("snackbar", { msg, color });
          } else {
            const msg =
              "Bilder konnten nicht hochgeladen werden. Bitte versuchen Sie es erneut.";
            const color = "error";
            that.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getBlockInfo() {
      let formData = new FormData();
      formData.append("id", this.id);
      formData.append("mode", this.mode);
      this.$http({
        method: "post",
        url: "getBlock.php",
        data: formData,
      })
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (response.data && response.data.success) {
            if (response.data.anlagen) {
              this.anlagen = response.data.anlagen;
            }
            if (response.data.documents) {
              this.documents = response.data.documents;
            }
            if (this.mode && this.mode === "edit") {
              this.block = response.data.block;
              let anlagen = response.data.block.anlagen.split(",");
              anlagen.forEach((id) => {
                let index = -1;
                const anlage = this.anlagen.find((a, i) => {
                  if (a.id === id) {
                    index = i;
                    return true;
                  }
                });
                if (anlage) {
                  this.blockanlagen.push(anlage);
                  this.anlagen.splice(index, 1);
                }
              });
            }
          }
        })
        .catch(function (error) {});
    },
    addAnlagen() {
      this.blockanlagen = this.blockanlagen.concat(this.selected);
      this.anlagen = this.anlagen.filter((item) => {
        return !this.selected.includes(item);
      });
      this.selected = [];
      this.dialog = false;
    },
    removeAnlage(item) {
      this.anlagen = this.anlagen.concat(item);
      this.blockanlagen.forEach((a, i) => {
        if (a.id === item.id) {
          this.blockanlagen.splice(i, 1);
        }
      });
    },
    openDialog() {
      this.dialog = true;
    },
    saveBlock() {
      if (this.$refs.form2.validate()) {
        let formData = new FormData();
        formData.append("name", this.block.name);
        formData.append("duration", this.block.duration || "");
        formData.append("price", this.block.price || "");
        formData.append("cycle", this.block.cycle || "");
        formData.append("images", this.block.images || "");
        let nextservice = "";
        if (this.block.nextservice && this.block.nextservice !== "0000-00-00") {
          nextservice = Math.round(
            new Date(this.block.nextservice).getTime() / 1000
          );
        }
        formData.append("nextservice", nextservice);
        let refdate = "";
        if (this.block.refdate && this.block.refdate !== "0000-00-00") {
          refdate = Math.round(new Date(this.block.refdate).getTime() / 1000);
        }
        formData.append("refdate", refdate);
        let ids = [];
        let groups = [];
        this.blockanlagen.forEach((a) => {
          ids.push(a.id);
          const items = JSON.parse(a.items);
          items.forEach((item) => {
            if (item.group && groups.indexOf(item.group) === -1) {
              groups.push(item.group);
            }
          });
        });
        formData.append("groups", groups.join(", "));
        formData.append("anlagen", ids.join(","));
        formData.append("mode", this.mode);
        if (this.mode === "new") {
          formData.append("customerid", this.id);
        } else {
          formData.append("id", this.id);
        }
        this.$http({
          method: "post",
          url: "editBlock.php",
          data: formData,
        })
          .then((response) => {
            if (
              response.data &&
              response.data.success === false &&
              response.data.missingToken
            ) {
              let axios = this.$http;
              this.$store
                .dispatch("logout", { axios })
                .then((resp) => {
                  this.$router.push("/login");
                })
                .catch((err) => {
                  console.log(err);
                });
            }
            if (response.data.success) {
              if (this.mode === "edit") {
                const msg =
                  "Der Wartungs-Block wurde erfolgreich aktualisiert.";
                const color = "success";
                this.$store.dispatch("snackbar", { msg, color });
              } else {
                const msg = "Der Wartungsblock wurde erfolgreich erstellt.";
                const color = "success";
                this.$store.dispatch("snackbar", { msg, color });
                this.$router.go(-1);
              }
            }else {
              const msg = "Beim Speichern ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.";
                const color = "error";
                this.$store.dispatch("snackbar", { msg, color });
            }
          })
          .catch(function (error) {
            const msg =
              "Beim Speichern ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
          });
      }
    },
    saveAnlagen() {
      this.blockanlagen = this.selected;
      this.dialog = false;
      return;
    },
  },
};
</script>
