var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('v-btn',{staticClass:"float-right",attrs:{"color":"primary","top":"","right":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("Zur Übersicht")])],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"8","lg":"6"}},[_c('div',{staticClass:"card-title"},[_vm._v("Wartungsblock Informationen")]),_c('v-card',{staticClass:"mx-auto",attrs:{"flat":""}},[_c('v-card-text',[_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-form',{ref:"form2"},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":""}},[_c('v-text-field',{attrs:{"label":"Name*","rules":[_vm.rules.required],"required":""},model:{value:(_vm.block.name),callback:function ($$v) {_vm.$set(_vm.block, "name", $$v)},expression:"block.name"}})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md8":""}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Referenz-Datum für Wartungszyklus","prepend-icon":"event","readonly":""},model:{value:(_vm.formattedRefDate),callback:function ($$v) {_vm.formattedRefDate=$$v},expression:"formattedRefDate"}},on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"no-title":"","first-day-of-week":"1"},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.block.refdate),callback:function ($$v) {_vm.$set(_vm.block, "refdate", $$v)},expression:"block.refdate"}})],1)],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md4":""}},[_c('v-text-field',{attrs:{"type":"number","label":"Wartungszyklus"},model:{value:(_vm.block.cycle),callback:function ($$v) {_vm.$set(_vm.block, "cycle", $$v)},expression:"block.cycle"}})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md6":""}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Nächste Wartung am*","prepend-icon":"event","readonly":"","rules":[_vm.rules.required],"required":""},model:{value:(_vm.formattedDate),callback:function ($$v) {_vm.formattedDate=$$v},expression:"formattedDate"}},on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","first-day-of-week":"1"},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.block.nextservice),callback:function ($$v) {_vm.$set(_vm.block, "nextservice", $$v)},expression:"block.nextservice"}})],1)],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md6":""}},[_c('v-text-field',{attrs:{"type":"number","label":"Preis"},model:{value:(_vm.block.price),callback:function ($$v) {_vm.$set(_vm.block, "price", $$v)},expression:"block.price"}})],1),_c('v-flex',{attrs:{"xs12":"","sm12":""}},[(_vm.mode === 'edit')?_c('div',[_vm._v(" Letzte Wartung: "+_vm._s(_vm.$formatDate(_vm.block.lastservice))+" ")]):_vm._e()])],1)],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","offset-md":"6","offset-lg":"6","sm":"12","md":"6","lg":"6"}},[_c('v-text-field',{attrs:{"append-icon":"search","label":"Suche"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"height":"inherit","search":_vm.search,"items":_vm.blockanlagen,"items-per-page":-1,"hide-default-footer":"","sort-by":"name"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Anlagen")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"success"},on:{"click":function($event){return _vm.openDialog()}}},on),[_vm._v(" Anlagen hinzufügen ")])]}}])},[_c('span',[_vm._v("Anlagen diesem Warungs-Block hinzufügen")])])],1)]},proxy:true},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"error","small":"","fab":""},on:{"click":function($event){return _vm.removeAnlage(item)}}},on),[_c('i',{staticClass:"material-icons"},[_vm._v("delete")])])]}}],null,true)},[_c('span',[_vm._v("Anlagen aus diesem Warungs-Block entfernen")])])],1)]}},{key:"item.timestamps",fn:function(ref){
var item = ref.item;
return [_c('small',[_vm._v("Erstellt am: "+_vm._s(_vm.$formatDate(item.created_at)))]),_c('br'),_c('small',[_vm._v("Aktualsiert am: "+_vm._s(_vm.$formatDate(item.updated_at)))])]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headersdocuments,"height":"inherit","hide-default-footer":"","items-per-page":-1,"items":_vm.documents},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Dokumente ("+_vm._s(_vm.documents.length)+")")])],1)]},proxy:true},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.$formatDateTime(item.created_at)))]}},{key:"item.filename",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"link",on:{"click":function($event){return _vm.$openDoc(item)}}},[_vm._v(" "+_vm._s(item.filename)+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center inline-flex"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.role != 'worker')?_c('v-btn',_vm._g({attrs:{"color":"error","small":"","fab":""},on:{"click":function($event){return _vm.confirmDeleteDoc(item)}}},on),[_c('i',{staticClass:"material-icons"},[_vm._v("delete")])]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Dokument löschen")])])],1)]}},{key:"footer",fn:function(){return [_c('hr',{staticClass:"my-5"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"9","md":"9","lg":"9"}},[_c('v-file-input',{attrs:{"color":"primary accent-4","counter":"","label":"Hier klicken um Dokumente hochzuladen","multiple":"","placeholder":"Hier klicken um Dokumente hochzuladen","prepend-icon":"mdi-paperclip","outlined":"","show-size":1000},model:{value:(_vm.docs),callback:function ($$v) {_vm.docs=$$v},expression:"docs"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3","lg":"3"}},[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.docs.length === 0},on:{"click":function($event){return _vm.uploadDocs()}}},[_vm._v("Dokumente Hochladen")])],1)],1)]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-btn',{attrs:{"color":"success","normal":""},on:{"click":function($event){return _vm.saveBlock()}}},[_vm._v("Wartungsblock speichern")])],1)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"1300px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Anlagen hinzufügen")])]),_c('v-card-text',[_c('v-container',[_c('v-data-table',{attrs:{"show-select":"","hide-default-footer":"","headers":_vm.headers,"items-per-page":-1,"height":"inherit","items":_vm.anlagen},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Anlagen")]),_c('v-spacer')],1)]},proxy:true},{key:"item.timestamps",fn:function(ref){
var item = ref.item;
return [_c('small',[_vm._v("Erstellt am: "+_vm._s(_vm.$formatDate(item.created_at)))]),_c('br'),_c('small',[_vm._v("Aktualsiert am: "+_vm._s(_vm.$formatDate(item.updated_at)))])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","disabled":!_vm.selected.length,"text":""},on:{"click":function($event){return _vm.addAnlagen()}}},[_vm._v(_vm._s(_vm.selected.length)+" Anlagen hinzufügen")]),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Schließen")])],1)],1)],1),_c('v-dialog',{attrs:{"width":"90%","transition":"dialog-bottom-transition"},model:{value:(_vm.image),callback:function ($$v) {_vm.image=$$v},expression:"image"}},[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.image = false;
          _vm.selectedimage = '';}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-spacer')],1),_c('v-img',{attrs:{"src":_vm.getImage(_vm.selectedimage),"contain":""}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }